import { useCallback, useEffect, useState } from 'react';
import debounce from '@hugsmidjan/qj/debounce';

type TSizes = {
  width: number;
  height: number;
  isMobile: boolean | undefined;
  isPhone: boolean | undefined;
};

export const useResize = () => {
  const [sizes, setSizes] = useState<TSizes>({
    width: 0,
    height: 0,
    isMobile: undefined,
    isPhone: undefined,
  });

  const handleResize = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setSizes({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.matchMedia('(max-width: 1079px)').matches,
      isPhone: window.matchMedia('(max-width: 719px)').matches,
    });
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', debounce(handleResize, 100));

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return { sizes };
};
