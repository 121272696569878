import classnames from 'classnames/bind';

import s from './ButtonSpinner.module.scss';

const c = classnames.bind(s);

export const ButtonSpinner = ({ className }: { className?: string }) => {
  return (
    <div
      className={c(s.loading, className)}
      role="alertdialog"
      aria-busy="true"
      aria-live="assertive"
    >
      <span className={s.loadingIndicator} />
    </div>
  );
};
