export type ErrorData = {
  message: string;
  code?: string;
};

export type ErrorProps = {
  code?: string;
} & ({ message: string; errors?: never } | { message?: never; errors: Array<ErrorData> });

export class CustomError extends Error {
  code?: string;
  errors: Array<ErrorData>;

  constructor(params: ErrorProps) {
    const { message, code, errors } = params;
    const error: ErrorData = message
      ? { message, ...(code ? { code } : {}) }
      : errors && errors[0]
      ? errors[0]
      : { message: 'Message missing' };

    super(error.message);
    this.errors = message ? [error] : errors ?? [];

    if (error.code) {
      this.code = error.code;
    }
  }
}

export class FetcherError extends CustomError {
  status?: number;

  constructor(
    options: {
      status?: number;
    } & ErrorProps
  ) {
    super(options);
    this.status = options.status;
  }
}
