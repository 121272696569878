import React, { Children, cloneElement, useContext } from 'react';
import Close from 'assets/svg/menu/close.svg';
import Logout from 'assets/svg/menu/logout.svg';
import classnames from 'classnames/bind';
import { UIContext } from 'context/ui';
import { useRouter } from 'next/router';

import { NavItem } from 'types/navigation';
import { clientFetch } from 'utils/clientFetch';

import { NavLink } from './NavLink';

import s from './Nav.module.scss';

const c = classnames.bind(s);

type IProps = {
  children: React.ReactNode;
  publicNav?: boolean;
};

export const Nav = ({ children, publicNav }: IProps) => {
  const { uiState, setUIState } = useContext(UIContext);
  const router = useRouter();

  const handleClose = () => {
    setUIState({ isNavOpen: false });
  };

  return (
    <nav
      className={c({ nav: true, open: uiState.isNavOpen })}
      aria-label="Main Navigation"
    >
      <div className={s.nav__inner}>
        <div className={s.nav__content}>
          <div className={s.nav__close}>
            <button onClick={handleClose} aria-label="Loka valmynd">
              <Close />
            </button>
          </div>
          <ul className={s.nav__list}>
            {Children.toArray(children).map((ch, i) => {
              const child = ch as React.ReactElement;
              const children = (child.props as NavItem).childPages;
              return (
                <li className={s.nav__item} key={i}>
                  {cloneElement(child, {
                    tabIndex: 0,
                    className: s.nav__link,
                  })}
                  {children && (
                    <ul className={s.nav__subList}>
                      {children.map((child, j) => {
                        return (
                          <li className={s.nav__subItem} key={j}>
                            <NavLink
                              to={child.link}
                              className={s.nav__subLink}
                              current={router.pathname.includes(child.link)}
                            >
                              {child.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          {!publicNav && (
            <button
              onClick={() => clientFetch('/api/auth/logout').then(() => router.push('/'))}
              className={s.nav__logout}
            >
              <Logout /> Útskrá
            </button>
          )}
        </div>
      </div>
      <button
        className={s.nav__backdrop}
        aria-label="Close Main Navigation"
        onClick={handleClose}
      />
    </nav>
  );
};
