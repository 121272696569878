// container Nav component, any data for nav structure should go here
// container gets imported into 'components/layout/Layout'

import Idgjold from 'assets/svg/menu/idgjold.svg';
import Lan from 'assets/svg/menu/lan.svg';
import Lifeyrisgreidslur from 'assets/svg/menu/lifeyrisgreidslur.svg';
import Lifeyrisrettindi from 'assets/svg/menu/lifeyrisrettindi.svg';
import Sereign from 'assets/svg/menu/sereign.svg';
import Settings from 'assets/svg/menu/settings.svg';
import Skjol from 'assets/svg/menu/skjol.svg';
import Umsokn from 'assets/svg/menu/umsokn.svg';
import Yfirlit from 'assets/svg/menu/yfirlit.svg';
import { useUiState } from 'context/useUiState';
import { useRouter } from 'next/router';

import { Button } from 'components/button/Button';
import { Nav } from 'components/nav/Nav';
import { NavLink } from 'components/nav/NavLink';

import { useSettings } from 'hooks/useSettings';
import { useUser } from 'hooks/useUser';
import { NavItem } from 'types/navigation';

const icons = {
  Idgjold: Idgjold,
  Lan: Lan,
  Lifeyrisgreidslur: Lifeyrisgreidslur,
  Lifeyrisrettindi: Lifeyrisrettindi,
  Sereign: Sereign,
  Settings: Settings,
  Skjol: Skjol,
  Yfirlit: Yfirlit,
  Umsokn: Umsokn,
};

export type NavContainerProps = {
  navigation: Array<NavItem>;
  publicNav?: boolean;
};

export const NavContainer = ({ navigation, publicNav }: NavContainerProps) => {
  const router = useRouter();
  const { setUIState, uiState } = useUiState();
  const { settings } = useSettings();
  const { user } = useUser(publicNav);
  const editorIds = settings?.editorNationalId ?? [];

  // we'll show the "format keys" button if the user is and admin and the `onlyWebmastersSeeKeys` setting is false
  // or if the current user national id is in the `editorIds` setting field
  const showFormatKeysButton =
    user &&
    ((user.userType === 'ADMIN' && !(settings?.onlyWebmastersSeeKeys ?? false)) ||
      editorIds.includes(user.ssn));

  return (
    <Nav publicNav={publicNav}>
      {navigation.map((itm, i) => {
        const Icon =
          itm.icon && itm.icon in icons ? icons[itm.icon as keyof typeof icons] : null;

        return (
          <NavLink
            key={i}
            to={itm.link}
            current={router.pathname.includes(itm.link)}
            childPages={itm.childPages}
          >
            {itm.icon && itm.icon in icons && <Icon />} {itm.title}
          </NavLink>
        );
      })}
      {showFormatKeysButton && (
        <Button
          variant="secondary"
          onClick={() => {
            setUIState({ showFormatKeys: !uiState.showFormatKeys });
          }}
        >
          {uiState.showFormatKeys ? 'Fela' : 'Sýna'} textalykla
        </Button>
      )}
    </Nav>
  );
};
