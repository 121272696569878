import { useCallback, useEffect, useRef, useState } from 'react';

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | 'top' | null>(
    null
  );
  const initialRender = useRef(false);
  const scrollPosition = useRef(0);

  const handleScroll = useCallback(() => {
    if (initialRender.current) {
      initialRender.current = false;

      return;
    }

    const position = window.scrollY;

    if (position <= 0) {
      setScrollDirection(null);
    } else if (position === 0) {
      setScrollDirection('top');
    } else {
      setScrollDirection(scrollPosition.current > position ? 'up' : 'down');
    }

    scrollPosition.current = position;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return scrollDirection;
};
