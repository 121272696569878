// container Nav component, any data for nav structure should go here
// container gets imported into 'components/layout/Layout'

import { useRouter } from 'next/router';

import { NavLink } from 'components/nav/NavLink';
import { NavSecondary } from 'components/nav/NavSecondary';

export const NavSecondaryContainer = ({
  childPages,
}: {
  childPages: Array<{ title: string; link: string }>;
}) => {
  const router = useRouter();

  return (
    <NavSecondary>
      {childPages.map((child, i) => (
        <NavLink key={i} to={child.link} current={router.pathname === child.link}>
          {child.title}
        </NavLink>
      ))}
    </NavSecondary>
  );
};
