import Head from 'next/head';

import defaults from './meta-defaults.json';

type IProps = {
  children?: React.ReactNode;
  title?: string;
  seoTitle?: string;
  description?: string;
  image?: string;
  favIconUrl?: string;
  plausibleDomain?: string;
};

export const Meta = ({
  title,
  seoTitle,
  description,
  image,
  children,
  favIconUrl,
}: IProps) => {
  const usedSeoTitle = seoTitle ?? title ?? defaults.title;

  return (
    <Head>
      <title>{title ?? defaults.title}</title>
      <meta name="description" content={description ?? defaults.description} key="desc" />
      <meta name="twitter:title" content={usedSeoTitle} key="twtitle" />
      <meta
        name="twitter:description"
        content={description ?? defaults.description}
        key="twdescription"
      />
      <meta property="og:title" content={usedSeoTitle} key="og:title" />
      <meta
        property="og:description"
        content={description ?? defaults.description}
        key="og:desc"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
        key="viewport"
      />
      {favIconUrl && (
        <>
          <link rel="icon" href={`${favIconUrl}?w=64&h=64`} key="favicon" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${favIconUrl}?w=180&h=180`}
            key="apple-touch-icon"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${favIconUrl}?w=32&h=32`}
            key="favicon-32"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${favIconUrl}?w=16&h=16`}
            key="favicon-16"
          />
        </>
      )}
      <meta
        name="msapplication-TileColor"
        content="#3d5a80"
        key="msapplication-TileColor"
      />
      <meta name="theme-color" content="#3d5a80" key="theme-color" />

      {(image || defaults.image) && (
        <>
          <meta property="og:image" content={image ?? defaults.image} key="image" />
          <meta name="twitter:image" content={image ?? defaults.image} key="twimage" />
        </>
      )}
      {children}
    </Head>
  );
};
