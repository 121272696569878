// a sane version of Link without having to write a child anchor & whatnot
import { useContext } from 'react';
import { UIContext } from 'context/ui';
import NextLink from 'next/link'; // alias of Link

import { fixLink } from 'utils/fixLink';

export type ILinkProps = {
  children: React.ReactNode;
  to: string;
  transition?: boolean;
  className?: string;
  [key: string]: unknown;
};

export const Link = ({
  children,
  to,
  transition = true,
  ...props
}: ILinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  // prop: transiton = opt in or out of a page transition
  // (i.e tabs might not require a transition)
  // defaults to active page transitions
  const { uiState, setUIState } = useContext(UIContext);
  const { prefersReducedMotion } = uiState;

  const fixedTo = fixLink(to);
  const isExternal = /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(fixedTo || '');

  if (isExternal) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={fixedTo} {...props}>
        {children}
      </a>
    );
  }

  const handleClick = () => {
    setUIState({
      isNavOpen: false,
      canTransition: prefersReducedMotion ? false : transition,
    });
  };

  return (
    <NextLink
      href={fixedTo}
      scroll={prefersReducedMotion ? true : !transition}
      {...props}
      onClick={handleClick}
    >
      {children}
    </NextLink>
  );
};
