import { Children, cloneElement } from 'react';
import classnames from 'classnames/bind';

import s from './NavSecondary.module.scss';

const c = classnames.bind(s);

type IProps = {
  children: React.ReactNode;
};

export const NavSecondary = ({ children }: IProps) => {
  const childArray = Children.toArray(children).filter(Boolean);

  return (
    <nav className={c({ navSecondary: true })} aria-label="Secondary Navigation">
      <ul className={s.navSecondary__list}>
        {childArray.map((child, i) => (
          <li className={s.navSecondary__item} key={i}>
            {cloneElement(child as React.ReactElement, {
              className: s.navSecondary__link,
            })}
          </li>
        ))}
      </ul>
    </nav>
  );
};
