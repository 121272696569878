import { defineMessages } from 'react-intl';

export const navigation = 'navigation';
export const navigationMessages = defineMessages({
  userGreeting: {
    id: `navigation:user-greeting`,
    defaultMessage: 'Góðan dag',
    description: 'Heilsa (greeting) fyrir innskráðan notanda',
  },
  overviewTitle: {
    id: 'navigation:overview-title',
    defaultMessage: 'Yfirlit',
    description: 'Heiti Yfirlits hnapps í valmynd',
  },
  entitlementTitle: {
    id: 'navigation:entitlement-title',
    defaultMessage: 'Lífeyrisréttindi',
    description: 'Heiti Lífeyrisréttinda hnapps í valmynd',
  },
  acquiredEntitlemtsTitle: {
    id: 'navigation:acquired-entitlements-title',
    defaultMessage: 'Áunnin réttindi',
    description: 'Heiti fyrir áunnin lífeyrisréttindi hnapp í valmynd',
  },
  otherEntitlementsTitle: {
    id: 'navigation:other-entitlements-title',
    defaultMessage: 'Réttindi í öðrum sjóðum',
    description: 'Heiti fyrir lífeyrisréttindi í öðrum sjóðum hnapp í valmynd',
  },
  personalPensionTitle: {
    id: 'navigation:personal-pension-title',
    defaultMessage: 'Séreign',
    description: 'Heiti séreignar hnapps í valmynd',
  },
  specifiedPersonalPensionTitle: {
    id: 'navigation:specified-personal-pension-title',
    defaultMessage: 'Tilgreind séreign',
    description: 'Heiti tilgreindar séreignar hnapps í valmynd',
  },
  pensionPaymentsTitle: {
    id: 'navigation:pension-payments-title',
    defaultMessage: 'Lífeyrisgreiðslur',
    description: 'Heiti lífeyrirsgreiðsla hnapps í valmynd',
  },
  contributionsTitle: {
    id: 'navigation:contributions-title',
    defaultMessage: 'Iðgjöld',
    description: 'Heiti iðgjalda hnapps í valmynd',
  },
  loanTitle: {
    id: 'navigation:loan-title',
    defaultMessage: 'Lán',
    description: 'Heiti lána hnapps í valmynd',
  },
  documentsTitle: {
    id: 'navigation:documents-title',
    defaultMessage: 'Skjöl',
    description: 'Heiti skjala hnapps í valmynd',
  },
  settingsTitle: {
    id: 'navigation:settings-title',
    defaultMessage: 'Mínar upplýsingar',
    description: 'Heiti stillinga (mína upplýsingar) hnapps í valmynd',
  },
  applicationTitle: {
    id: 'navigation:application-title',
    defaultMessage: 'Umsóknarvefur',
    description: 'Heiti umsóknarvefs hnapps í valmynd',
  },
  pensionPortalTitle: {
    id: 'navigation:pension-portal-title',
    defaultMessage: 'Lífeyrisgátt',
    description: 'Heiti lífeyrisgáttar hnapps í valmynd',
  },
});
