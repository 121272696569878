import { useState } from 'react';
import Chevron from 'assets/svg/ui/chevron-down.svg';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import { PartialUser } from 'hooks/useUser';
import { logger } from 'utils/logger';

import s from './UserSelect.module.scss';

const c = classNames.bind(s);

export type SelectableUser = { ssn: string; name: string };

type Props = {
  name: string;
  onUserSelect: (ssn: string) => Promise<PartialUser | null>;
  users: Array<SelectableUser>;
};

export const UserSelect = ({ name, users, onUserSelect }: Props) => {
  const [showUsers, setShowUsers] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  return (
    <div className={s.userSelect}>
      <button className={s.userSelect__current} onClick={() => setShowUsers(!showUsers)}>
        {name}
        <Chevron className={c(s.userSelect__chevron, { active: showUsers })} />
      </button>
      {showUsers && (
        <ul className={s.userSelect__users}>
          {users.map((user) => (
            <li key={user.ssn} className={s.userSelect__user}>
              <button
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  onUserSelect(user.ssn)
                    .then((potentialUser) => {
                      if (potentialUser) {
                        router.reload();
                      }
                    })
                    .catch((e) => {
                      logger.error('unable to switch users', { exception: e as Error });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
              >
                {user.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
