import { IronSessionData } from 'iron-session';
import useSWR from 'swr';

import { User } from 'types/iron';
import { swrFetcher } from 'utils/clientFetch';

export type PartialUser = Pick<User, 'name' | 'ssn' | 'userType' | 'userHasAccessTo'> & {
  originalUser?: IronSessionData['originalDefaultMember'];
};

export const useUser = (publicNav = false) => {
  const { data, error } = useSWR<PartialUser>(
    publicNav ? null : '/api/auth/user',
    swrFetcher
  );
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
  };
};
