import { useMemo } from 'react';
import { navigationMessages } from 'messages/messages.navigation';

import { useSettings } from 'hooks/useSettings';
import { NavItem } from 'types/navigation';

import { useMessages } from './useMessages';

export const useNavigationItems = () => {
  const { settings } = useSettings();
  const { formatMessage } = useMessages();

  // eslint-disable-next-line complexity
  const navigationItems = useMemo<Array<NavItem>>(() => {
    return [
      {
        title: formatMessage(navigationMessages.overviewTitle) ?? 'Yfirlit',
        link: '/minar-sidur/yfirlit',
        icon: 'Yfirlit',
        enabled: settings?.displayOverview !== false,
      },
      {
        title: formatMessage(navigationMessages.entitlementTitle) ?? 'Lífeyrisréttindi',
        link: '/minar-sidur/lifeyrisrettindi',
        icon: 'Lifeyrisrettindi',
        childPages: [
          {
            title:
              formatMessage(navigationMessages.acquiredEntitlemtsTitle) ??
              'Áunnin réttindi',
            link: '/minar-sidur/lifeyrisrettindi',
          },
          {
            title:
              formatMessage(navigationMessages.otherEntitlementsTitle) ??
              'Réttindi í öðrum sjóðum',
            link: '/minar-sidur/lifeyrisrettindi/rettindi-i-odrum-sjodum',
          },
        ],
        enabled: settings?.displayEntitlement !== false,
      },
      {
        title: formatMessage(navigationMessages.personalPensionTitle) ?? 'Séreign',
        link: '/minar-sidur/sereign',
        icon: 'Sereign',
        childPages: [
          {
            title: formatMessage(navigationMessages.personalPensionTitle) ?? 'Séreign',
            link: '/minar-sidur/sereign',
          },
          {
            title:
              formatMessage(navigationMessages.specifiedPersonalPensionTitle) ??
              'Tilgreind séreign',
            link: '/minar-sidur/sereign/tilgreind-sereign',
          },
        ],
        enabled: settings?.displayPersonalPension !== false,
      },
      {
        title:
          formatMessage(navigationMessages.pensionPaymentsTitle) ?? 'Lífeyrisgreiðslur',
        link: '/minar-sidur/lifeyrisgreidslur',
        icon: 'Lifeyrisgreidslur',
        childPages: [
          {
            title:
              formatMessage(navigationMessages.pensionPaymentsTitle) ??
              'Lífeyrisgreiðslur',
            link: '/minar-sidur/lifeyrisgreidslur',
          },
        ],
        enabled: settings?.displayPensionPayments !== false,
      },
      {
        title: formatMessage(navigationMessages.contributionsTitle) ?? 'Iðgjöld',
        link: '/minar-sidur/idgjold',
        icon: 'Idgjold',
        enabled: settings?.displayContributions !== false,
      },
      {
        title: formatMessage(navigationMessages.loanTitle) ?? 'Lán',
        link: '/minar-sidur/lan',
        icon: 'Lan',
        childPages: [
          {
            title: formatMessage(navigationMessages.loanTitle) ?? 'Lán',
            link: '/minar-sidur/lan',
          },
          // { title: 'Skattaframtal', link: '/temp/forms' },
        ],
        enabled: settings?.displayLoans !== false,
      },
      {
        title: formatMessage(navigationMessages.documentsTitle) ?? 'Skjöl',
        link: '/minar-sidur/skjol',
        icon: 'Skjol',
        enabled: settings?.displayDocuments !== false,
      },
      {
        title: formatMessage(navigationMessages.settingsTitle) ?? 'Mínar upplýsingar',
        link: '/minar-sidur/stillingar',
        icon: 'Settings',
        enabled: settings?.displaySettings !== false,
      },
      {
        title: formatMessage(navigationMessages.applicationTitle) ?? 'Umsóknarvefur',
        link: settings?.applicationUrl ? `${settings.applicationUrl}` : '/',
        externalLink: true,
        icon: 'Umsokn',
        enabled: settings?.displayApplication !== false,
      },
      {
        title: formatMessage(navigationMessages.pensionPortalTitle) ?? 'Lífeyrisgátt',
        link: settings?.pensionPortalLink ? `${settings.pensionPortalLink}` : '/',
        icon: 'Skjol',
        enabled: settings?.pensionPortal !== false,
      },
    ].filter(({ enabled }) => enabled);
  }, [settings, formatMessage]);

  return navigationItems;
};
