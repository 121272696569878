import { FetcherError } from './error';
import { logger } from './logger';

const LOG_CATEGORY = 'fetch';

export async function clientFetch<T>(url: string, options = {}) {
  let response = null;

  try {
    response = await fetch(url, options);
  } catch (e) {
    logger.error('unable to fetch', {
      exception: e as Error,
      category: LOG_CATEGORY,
      metadata: { url },
    });
  }
  let json: unknown;

  try {
    if (response && response.ok) {
      json = await response.json();
    }
  } catch (e) {
    logger.error('unable to parse', {
      exception: e as Error,
      category: LOG_CATEGORY,
      metadata: { url },
    });
    return null;
  }

  return json as T;
}

export async function swrFetcher<T>(url: string, options?: RequestInit) {
  let response = null;

  try {
    response = await fetch(url, options);
  } catch (e) {
    logger.error('unable to fetch', {
      exception: e as Error,
      category: LOG_CATEGORY,
      metadata: { url },
    });
  }

  let json: unknown;

  try {
    if (response) {
      json = await response.json();
    }
  } catch (e) {
    logger.error('unable to parse', {
      exception: e as Error,
      category: LOG_CATEGORY,
      metadata: { url },
    });
  }

  // Throwing will trigger SWR onError callbacks
  if (response && !response.ok) {
    let message = 'No error message available';
    const maybeJson = json as object | null;
    if (maybeJson && 'error' in maybeJson) {
      message = (maybeJson as { error: string }).error;
    }

    const error = new FetcherError({
      status: response.status,
      message: message,
    });

    throw error;
  }

  return json as T;
}
