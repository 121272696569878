import { GoogleTagManager } from '@next/third-parties/google';

const trackingId = process.env.NEXT_PUBLIC_GTM_ID;

export const TrackingMeta = () => {
  if (!trackingId) {
    return null;
  }

  return <GoogleTagManager gtmId={trackingId} />;
};
