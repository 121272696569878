import { useSettings } from 'hooks/useSettings';

import s from './Logo.module.scss';

export const Logo = () => {
  const { settings } = useSettings();

  if (!settings?.logo?.url) {
    return null;
  }

  return <img src={settings.logo.url} alt="Logo" className={s.logo} />;
};
